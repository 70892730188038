/* eslint-disable camelcase */
import React, { useEffect, useMemo } from 'react';
import useStore from 'state/state';
import { func } from 'prop-types';
import axios from 'axios';

import Button from 'components/elements/Button/Button';
import Loader from 'components/elements/Loader/Loader';
import styles from './UserList.module.scss';
import SortingButton from './SortingButton/SortingButton';

const tokenDataState = (state) => state.tokenData;
const isErrorState = (state) => state.setIsError;
const listOfUsersState = (state) => [state.listOfUsers, state.setListOfUsers];
// const listOfGroupsState = (state) => [
//   state.listOfGroups,
//   state.setListOfGroups,
// ];
const userListSortingState = (state) => [
  state.userListSorting,
  state.setUserListSorting,
];

const UserList = ({ setEditUserObj }) => {
  const tokenData = useStore(tokenDataState);
  const setIsError = useStore(isErrorState);
  const [listOfUsers, setListOfUsers] = useStore(listOfUsersState);
  // const [listOfGroups, setListOfGroups] = useStore(listOfGroupsState);
  const [userListSorting, setUserListSorting] = useStore(userListSortingState);
  const userListRoleFilter = '';

  const config = {
    headers: {
      Authorization: `Bearer ${tokenData}`,
      'x-api-key': process.env.API_KEY,
      'X-Origin': window.origin,
    },
  };

  const fetchUserList = () => {
    axios
      .post(
        `${process.env.URL}/user/listWithLatestActivity`,
        { filter: { admin_user: 0 } },
        config
      )
      .then((res) => {
        if (res?.data?.length) {
          const retrievedUsers = res.data.map((u) => {
            let date = '';
            let sort_date;
            if (u.last_log_activity || u.last_page_activity) {
              if (u.last_log_activity && u.last_page_activity) {
                // Both exists so they should be compared
                const lastLog = new Date(u.last_log_activity).getTime();
                const lastPage = new Date(u.last_page_activity).getTime();
                if (lastLog > lastPage) {
                  date = u.last_log_activity;
                  sort_date = lastLog;
                } else {
                  date = u.last_page_activity;
                  sort_date = lastPage;
                }
              } else if (u.last_log_activity) {
                const lastLog = new Date(u.last_log_activity).getTime();
                // last_log_activity exists and should be the value
                date = u.last_log_activity;
                sort_date = lastLog;
              } else {
                const lastPage = new Date(u.last_page_activity).getTime();
                // last_page_activity exists and should be the value
                date = u.last_page_activity;
                sort_date = lastPage;
              }
            } else {
              date = 'No activity yet';
              sort_date = 0;
            }
            return {
              ...u,
              date,
              sort_date,
            };
          });
          setListOfUsers(retrievedUsers);
        } else {
          setIsError('Something went wrong, user list was not collected.');
        }
      })
      .catch((err) => {
        console.log(err);
        setIsError('Something went wrong, user list was not collected.');
      });
  };

  // const fetchGroups = () => {
  //   axios
  //     .get(`${process.env.URL}/group/list`, config)
  //     .then((res) => {
  //       const groups = [];
  //       res.data.map((gr) => {
  //         const display_name = gr.group_name.split(' - ');
  //         return groups.push({ ...gr, display_name: display_name[1] });
  //       });
  //       setListOfGroups(groups);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setIsError('Something went wrong, user groups was not collected.');
  //     });
  // };

  useEffect(() => {
    if (!listOfUsers) {
      fetchUserList();
    }
    // if (!listOfGroups) {
    //   fetchGroups();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredListOfUsers = useMemo(() => {
    if (!userListRoleFilter) {
      return listOfUsers;
    }

    return (listOfUsers || []).filter((user) => {
      if (userListRoleFilter === 'administrators') {
        return user.admin_user === '1';
      }

      return user.user_groups.includes(userListRoleFilter);
    });
  }, [listOfUsers, userListRoleFilter]);

  const sortedListOfUsers = useMemo(() => {
    if (!userListSorting.field) {
      return filteredListOfUsers;
    }

    const returnSortingType = (val) => {
      if (typeof val === 'string') {
        return val.toUpperCase().trim();
      }
      if (val === null) {
        return '';
      }
      return val;
    };

    const users = [...(filteredListOfUsers || [])];
    return users.sort((a, b) => {
      const paramA = returnSortingType(a[userListSorting.field]);
      const paramB = returnSortingType(b[userListSorting.field]);
      if (userListSorting.order === 'asc') {
        return paramA < paramB ? -1 : 1;
      }

      return paramA < paramB ? 1 : -1;
    });
  }, [filteredListOfUsers, userListSorting.field, userListSorting.order]);

  const sortByField = (field) => {
    let tempSort = { ...userListSorting };

    // No userListSorting been done before (1st click)
    if (!userListSorting.field) {
      tempSort = {
        ...tempSort,
        field,
      };
    } else if (userListSorting.field && userListSorting.field === field) {
      // Sorting has been done before, change to desc (2nd click)
      if (userListSorting.order === 'asc') {
        tempSort = {
          ...tempSort,
          order: 'desc',
        };
      } else {
        // Reset to no sort (3rd click)
        tempSort = {
          field: null,
          order: 'asc',
        };
      }
    } else {
      // Table has been sorted, but sorting changed to a new column
      // Sort by this field asc (same as 1st click)
      tempSort = {
        field,
        order: 'asc',
      };
    }

    setUserListSorting(tempSort);
  };

  return (
    <div className={styles.userListWrapper}>
      {sortedListOfUsers ? (
        <ul className={styles.userList}>
          <li className={styles.header}>
            <p>
              <SortingButton
                field="first_name"
                currentField={userListSorting.field}
                currentOrder={userListSorting.order}
                onClick={() => sortByField('first_name')}
              >
                First name
              </SortingButton>
            </p>
            <p>
              <SortingButton
                field="last_name"
                currentField={userListSorting.field}
                currentOrder={userListSorting.order}
                onClick={() => sortByField('last_name')}
              >
                Last name
              </SortingButton>
            </p>
            <p>
              <SortingButton
                field="company"
                currentField={userListSorting.field}
                currentOrder={userListSorting.order}
                onClick={() => sortByField('company')}
              >
                Company
              </SortingButton>
            </p>
            <p className={styles.email}>E-mail</p>
            <div>
              <SortingButton
                field="sort_date"
                currentField={userListSorting.field}
                currentOrder={userListSorting.order}
                onClick={() => sortByField('sort_date')}
              >
                Last activity
              </SortingButton>
            </div>
          </li>
          {sortedListOfUsers.map((u) => (
            <li key={u.email}>
              <p>{u.first_name}</p>
              <p>{u.last_name}</p>
              <p>{u.company}</p>
              <p className={styles.email}>{u.email}</p>
              <div>
                <span>{u.date}</span>
              </div>
              <Button
                onClick={() => {
                  setEditUserObj(u);
                }}
              >
                View
              </Button>
            </li>
          ))}
        </ul>
      ) : (
        <Loader />
      )}
    </div>
  );
};

UserList.propTypes = {
  setEditUserObj: func.isRequired,
};

export default UserList;
