/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { shape, string, func } from 'prop-types';
import Button from 'components/elements/Button/Button';
import axios from 'axios';
import useStore from 'state/state';
import clsx from 'clsx';

import Loader from 'components/elements/Loader/Loader';

import Icon from 'assets/images/doc.svg';

import styles from './ViewUserPage.module.scss';

const tokenDataState = (state) => state.tokenData;
const isErrorState = (state) => state.setIsError;

const ViewUserPage = ({ viewUserObj, setViewUserObj }) => {
  const setIsError = useStore(isErrorState);
  const tokenData = useStore(tokenDataState);
  const [userData, setUserData] = useState(null);
  const [accountCreatedAt, setAccountCreatedAt] = useState('');
  const [accountRegisteredAt, setAccountRegisteredAt] = useState('');

  const msToTime = (duration) => {
    // const milliseconds = Math.floor((duration % 1000) / 100);
    let seconds = Math.floor((duration / 1000) % 60);
    let minutes = Math.floor((duration / (1000 * 60)) % 60);
    let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    seconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${hours}:${minutes}:${seconds}`;
  };

  const config = {
    headers: {
      Authorization: `Bearer ${tokenData}`,
      'x-api-key': process.env.API_KEY,
      'X-Origin': window.origin,
    },
  };

  const fetchUserActivation = () => {
    axios
      .post(
        `${process.env.URL}/userLog/userActivation`,
        { userid: viewUserObj.userid },
        config
      )
      .then((res) => {
        const { first_user_login, user_invitation } = res.data;

        if (first_user_login) {
          setAccountRegisteredAt(first_user_login.created_at);
        } else {
          setAccountRegisteredAt('Not registered by user');
        }

        if (user_invitation) {
          setAccountCreatedAt(user_invitation.created_at);
        } else {
          setAccountCreatedAt('-');
        }
      })
      .catch((err) => {
        console.log(err);
        // setIsError('Something went wrong, user list was not collected.');
      });
  };

  const fetchUserLog = (page = 0, data = null) => {
    let previousData = data;
    axios
      .post(
        `${process.env.URL}/userLog/details`,
        { userid: viewUserObj.userid, page },
        config
      )
      .then((res) => {
        if (!previousData) {
          previousData = res.data;
        } else {
          previousData.file_downloads = previousData.file_downloads.concat(
            res.data.file_downloads
          );
          previousData.general_log = previousData.general_log.concat(
            res.data.general_log
          );
        }
        if (
          res.data.file_downloads.length < res.data.max_number_of_results &&
          res.data.general_log.length < res.data.max_number_of_results
        ) {
          previousData.general_log = previousData.general_log.map(
            (logEvent, index) => {
              const defaultValue = 'N/A';
              let time_on_page = defaultValue;
              if (index > 0) {
                const breakPoint = 7200000; // 2 hours
                const nextTime = new Date(
                  previousData.general_log[index - 1].created_at
                ).getTime();
                const startTime = new Date(logEvent.created_at).getTime();
                if (nextTime - startTime < breakPoint) {
                  time_on_page = nextTime - startTime;
                }
              }
              return {
                ...logEvent,
                time_on_page:
                  time_on_page !== defaultValue
                    ? msToTime(time_on_page)
                    : time_on_page,
              };
            }
          );
          setUserData(previousData);
        } else {
          fetchUserLog(page + 1, previousData);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsError('Unable to get the log for the current user');
      });
  };

  useEffect(() => {
    if (!userData) {
      fetchUserLog();
      fetchUserActivation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCancel = () => {
    setViewUserObj(null);
  };

  return (
    <div className={styles.wrapper}>
      <Button onClick={onCancel}>Go back</Button>
      <div className={styles.header}>
        <div className={styles.userInfoWrapper}>
          <div>
            <p>{`${viewUserObj.first_name} ${viewUserObj.last_name}`}</p>
            <p>{`${viewUserObj.company}`}</p>
            <p>{`${viewUserObj.email}`}</p>
          </div>
          <div>
            <div className={styles.userInfoBox}>
              <p>Invite sent</p>
              <p>{accountCreatedAt}</p>
            </div>
            <div className={styles.userInfoBox}>
              <p>Account registered</p>
              <p>{accountRegisteredAt}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.contentWrapper}>
        {userData ? (
          <div>
            <h3>Downloads</h3>
            {/* Downloads */}
            {userData?.file_downloads?.length > 0 ? (
              <>
                <div className={clsx(styles.tableRow, styles.tableHeader)}>
                  <p>Filename</p>
                  <p>Category</p>
                  <p>Date/time</p>
                </div>
                {userData.file_downloads.map((file) => (
                  <div key={file.created_at} className={styles.tableRow}>
                    <p>
                      <a
                        href={`${file.file_url}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Icon /> <span>{` ${file.filename}`}</span>
                      </a>
                    </p>
                    <p>{file.file_category}</p>
                    <p>{file.created_at}</p>
                  </div>
                ))}
              </>
            ) : (
              <p>No files downloaded</p>
            )}
            <h3>Page views</h3>
            {/* Page views */}
            {userData?.general_log?.length > 0 ? (
              <>
                <div className={clsx(styles.tableRow, styles.tableHeader)}>
                  <p>Page</p>
                  <p>Date/time</p>
                  <p>Time on page</p>
                </div>

                {userData.general_log.map((log) => (
                  <div
                    key={log.created_at}
                    className={clsx(styles.tableRow, styles.second)}
                  >
                    <p>{log.url}</p>
                    <p>{log.created_at}</p>
                    <p>{log.time_on_page}</p>
                  </div>
                ))}
              </>
            ) : (
              <p>No events tracked</p>
            )}
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};
ViewUserPage.propTypes = {
  viewUserObj: shape({
    first_name: string,
    last_name: string,
    company: string,
    address: string,
    zipcode: string,
    city: string,
    country: string,
    email: string,
    userid: string,
  }).isRequired,
  setViewUserObj: func.isRequired,
};

export default ViewUserPage;
