import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';

import useStore from 'state/state';

import Home from 'components/Home/Home';
import NotFound from 'components/NotFound/NotFound';
import Logo from 'assets/images/logo.svg';

import styles from './app.module.scss';

const isErrorState = (state) => [state.isError, state.setIsError];
const isInfoMessageState = (state) => [
  state.isInfoMessage,
  state.setIsInfoMessage,
];
const tokenDataState = (state) => state.setTokenData;

const App = () => {
  const location = useLocation();
  const [isError, setIsError] = useStore(isErrorState);
  const [isInfoMessage, setIsInfoMessage] = useStore(isInfoMessageState);
  const setTokenData = useStore(tokenDataState);

  const routerTransitions = useTransition(
    location,
    (routeLocation) => routeLocation.pathname,
    {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
    }
  );

  useEffect(() => {
    const savedToken = sessionStorage.getItem('token');
    if (savedToken) {
      setTokenData(savedToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        setIsError(null);
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (isInfoMessage) {
      setTimeout(() => {
        setIsInfoMessage(null);
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInfoMessage]);

  return (
    <div className={styles.wrapper}>
      <Logo className={styles.topLogo} />
      {isError && <div className={styles.errorModal}>{isError}</div>}
      {isInfoMessage && (
        <div className={`${styles.errorModal} ${styles.infoModal}`}>
          {isInfoMessage}
        </div>
      )}

      {routerTransitions.map(({ item, props, key }) => (
        <animated.div className={styles.routesWrapper} key={key} style={props}>
          <Switch location={item}>
            <Route exact path="/" component={Home} />
            <Route component={NotFound} />
          </Switch>
        </animated.div>
      ))}
    </div>
  );
};

export default App;
